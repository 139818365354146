import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { AUTH_JWT_ACTIONS } from '@/common/enums';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { getCsrfToken } from 'next-auth/react';
import { JIBBLE_STATUSES } from '../../queries/admins/useJibbleClockInStatusQuery';

export interface Request {
  userId: string;
  activity: 'break' | 'work';
  auto: boolean;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

const HOST_URL = `${process.env.NEXT_PUBLIC_HOST_URL}`;

const updateJibbleStatus = async (action: AUTH_JWT_ACTIONS, payload: { jibbleStatus: 'Inactive' | 'Active' }) => {
  let _body = { action, data: payload };

  await fetch(`${HOST_URL}/api/auth/session`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      csrfToken: await getCsrfToken(),
      data: _body,
    }),
  });
};

export const useJibbleClockInMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<string> => {
    const { activity, auto } = value;
    const { data } = await axiosInterceptorInstance.post(`admins/${value.userId}/clock-in`, {
      activity,
      auto,
    });
    return data;
  };

  return useMutation({
    mutationKey: ['admins', 'clock-in'],
    mutationFn: _mutation,
    ...options,
    onSettled: (data, error) => {
      if (!error) {
        updateJibbleStatus(AUTH_JWT_ACTIONS.UPDATE_JIBBLE_STATUS, { jibbleStatus: JIBBLE_STATUSES.ACTIVE });
        queryClient.invalidateQueries({ queryKey: ['admins/activity/status'] });
      }
    },
  });
};

'use client';
import { Logout } from '@/components/svg';
import { Button } from '@/components/ui';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { UserSquare } from '@/components/svg';
import { useQueryClient } from '@tanstack/react-query';
import LocaleSwitcher, { Locale } from './locale-switcher';
import { useLocale, useTranslations } from 'next-intl';
import { useState } from 'react';
import { AlarmClockOff, AlarmClockPlusIcon, Utensils } from 'lucide-react';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useJibbleClockOutMutation } from '@/hooks/fetchers/mutations/admins/useJibbleClockOutMutation';
import { toast, ToastTypeEnums } from '../toast';
import { useJibbleClockInMutation } from '@/hooks/fetchers/mutations/admins/useJibbleClockInMutation';
import { JIBBLE_STATUSES, useJibbleClockInStatusQuery } from '@/hooks/fetchers/queries/admins/useJibbleClockInStatusQuery';

export function HeaderActionsButtons({ callbackUrl }: { callbackUrl: string }) {
  const [lng, setLng] = useState(0);
  const t = useTranslations();
  const { checkPermissions, session } = useIsAllowed();
  const locale = useLocale() as Locale;
  const queryClient = useQueryClient();
  const router = useRouter();

  const { data: jibbleStatus } = useJibbleClockInStatusQuery({
    enabled: checkPermissions([PERMISSIONS_TYPES.VIEW_JIBBLE_TRACKER_OPTIONS])
  });

  const { mutate: clockOut, isPending: clockOutPending } = useJibbleClockOutMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
      });
    },
  });

  const { mutate: clockIn, isPending: clockInPending } = useJibbleClockInMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
      });
    },
  });

  const handleLogOut = () => {
    queryClient.clear();
    signOut({ callbackUrl });
  };

  const handleRedirectToProfile = () => {
    router.push('/dashboard/my-profile');
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_ENV === 'develop' || process.env.NEXT_PUBLIC_ENV === 'local' ? (
        <>
          {lng === 8 && <LocaleSwitcher className="mr-2" locale={locale} />}
          <div className="absolute right-0 top-0 size-5" onClick={() => setLng((prev) => ++prev)}>
            {' '}
          </div>
        </>
      ) : null}
      {checkPermissions([PERMISSIONS_TYPES.VIEW_JIBBLE_TRACKER_OPTIONS]) && (
        <div className="pr-4">
          <Button disabled={clockInPending || jibbleStatus?.status === JIBBLE_STATUSES.ACTIVE} size="icon" variant="ghost">
            <AlarmClockPlusIcon
              onClick={() => clockIn({ userId: session?.user.userId as string, auto: false, activity: 'work' })}
              className="size-6 text-gray-700"
            />
          </Button>
          <Button disabled={clockOutPending || jibbleStatus?.status === JIBBLE_STATUSES.CLOCKED_OUT} size="icon" variant="ghost">
            <AlarmClockOff
              onClick={() => clockOut({ userId: session?.user.userId as string })}
              className="size-6 text-gray-700"
            />
          </Button>
          <Button disabled={clockInPending || jibbleStatus?.status === JIBBLE_STATUSES.BREAK} size="icon" variant="ghost">
            <Utensils
              onClick={() => clockIn({ userId: session?.user.userId as string, auto: false, activity: 'break' })}
              className="size-6 text-gray-700"
            />
          </Button>
        </div>
      )}
      <Button size="icon" variant="ghost">
        <UserSquare onClick={handleRedirectToProfile} className="size-6 text-gray-700" />
      </Button>
      <Button size="icon" variant="ghost" onClick={handleLogOut}>
        <Logout className="size-6 text-gray-700" />
      </Button>
    </>
  );
}
